<template>
  <div class="app-content content" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="content-body">
        <b-card no-body>
          <div class="m-2">
          </div>
          <div class="mx-2 mb-2">
            <div class="row">
              <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">
            Показано с {{ orders.from }} по {{ orders.to }} из {{ orders.total }} записей</span>
              </div>
              <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="orders.total"
                    :per-page="orders.per_page"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18"/>
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18"/>
                  </template>
                </b-pagination>
              </div>
            </div>
          </div>
          <div class="position-relative table-responsive">
            <table class="table b-table">
              <thead>
              <!---->
              <tr>
                <th>#</th>
                <th>Дата</th>
                <th>ТТН</th>
                <th>Статус</th>
                <th>К-во</th>
                <th>Вес (кг)</th>
                <th>Сумма</th>
                <th>Сумма наложки</th>
                <th>Комментарий</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="order in orders.data" :key="order.id">
                <td>
                  <router-link :to="{ name: 'order-details', params: { id: order.id }}" class="font-weight-bold">
                    #{{ order.id }}
                  </router-link>
                </td>
                <td>{{ $formatters.formattedDate(order.created_at) }}</td>
                <td>{{ order.ttn }}</td>
                <td>
                  <b-avatar
                      :id="`invoice-row-${order.id}`"
                      size="32"
                      :variant="`light-${getIcon(order.status_id).variant}`"
                  >
                    <feather-icon :icon="getIcon(order.status_id).icon"/>
                  </b-avatar>
                  <b-tooltip :target="`invoice-row-${order.id}`" placement="top">
                    <p class="mb-0">{{ order.status.name }}</p>
                  </b-tooltip>
                </td>
                <td>{{ order.qty }}</td>
                <td>{{ order.weight / 1000 }}</td>
                <td>
            <span class="amount" v-for="(item, index) in order.sum" :key="index">
              <span class="amount">{{ item.amount }}</span> <span class="currency">{{ item.currency }}</span>  <br/>
            </span>
                </td>
                <td>{{ order.client_price }}</td>
                <td>{{ order.comments }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="mx-2 mb-2">
            <div class="row">
              <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">
            Показано с {{ orders.from }} по {{ orders.to }} из {{ orders.total }} записей</span>
              </div>
              <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="orders.total"
                    :per-page="orders.per_page"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18"/>
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18"/>
                  </template>
                </b-pagination>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard, BFormInput, BButton, BAvatar, BPagination, BTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  name: 'OrderList',
  components: {
    BCard, BFormInput, BButton, BAvatar, BPagination, BTooltip, vSelect,
  },
  data() {
    return {
      currentPage: 1,
      statuses: ['Downloaded', 'Draft', 'Paid', 'Partial Payment', 'Past Due'],
      orders: {
        current_page: 1,
        data: [],
        from: null,
        to: null,
        total: null,
        per_page: 10,
      },
    };
  },
  watch: {
    async currentPage(newValue) {
      this.orders.current_page = newValue;
      await this.getOrders();
    },
  },
  async mounted() {
    await this.getOrders();
  },
  methods: {
    async getOrders() {
      this.orders = (await this.$api.orders.list({
        ...(this.orders.current_page > 1 && { page: this.orders.current_page }),
        limit: 25,
      })).data;
    },
    getIcon(status) {
      if (status === 2) return { variant: 'warning', icon: 'PieChartIcon' };
      if (status === 4) return { variant: 'primary', icon: 'CheckCircleIcon' };
      if (status === 1) return { variant: 'primary', icon: 'SaveIcon' };
      if (status === 7) return { variant: 'success', icon: 'SendIcon' };
      if (status === 5) return { variant: 'secondary', icon: 'XIcon' };
      if (status === 6) return { variant: 'danger', icon: 'InfoIcon' };
      return { variant: 'secondary', icon: 'XIcon' };
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.amount {
  white-space: nowrap;

  & span.amount {
    font-size: 18px;

  }

  & span.currency {
    font-size: 10px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
